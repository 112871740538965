import { apiActivity } from '@api/activity';
import { useBillingAccount } from '@context/BillingAccountContextProvider';
import useFetchSWR from '@hooks/useFetchSWR';
import { Activity } from '@typedefs/Activity';
import { ENV } from '@utils/environment';
import moment from 'moment';

const ENDPOINT_DATE_FORMAT_STRING = 'YYYY-MM-DD';
const LIMIT = 4;
const START_DATE = moment().subtract(3, 'days').format(ENDPOINT_DATE_FORMAT_STRING);
const END_DATE = moment().format(ENDPOINT_DATE_FORMAT_STRING);

type UseActivyStreamReturnType = {
  activityStream: Activity[];
  error: string;
  loading: boolean;
};

const useActivityStream = (
  activities: Record<string, { label: string; value?: string | undefined }>,
  searchActivity: { label: string; value?: string | undefined },
  language: string,
  limit = LIMIT
): UseActivyStreamReturnType => {
  const { accountData } = useBillingAccount();
  const { accountNumber } = accountData;

  const { data, error, loading } = useFetchSWR(
    accountNumber ? ['logs/range', limit, searchActivity.value, accountNumber] : null,
    (url, activityLimit, source) =>
      apiActivity({
        url,
        method: 'get',
        payload: {
          'scopes[account]': accountNumber,
          'scopes[user]': accountData?.primaryUser?.id ?? undefined,
          limit: activityLimit,
          source,
          startDate: START_DATE,
          endDate: END_DATE
        },
        headers: {
          'M360-Activity-Context': `web:${ENV}:marketing360Url:${language}`
        }
      })
  );

  const activityStream = data?.data?.response.map(
    (activity: Omit<Activity, 'source'> & { source: string }) => ({
      ...activity,
      source: activities[activity.source] || { label: activity.source, value: activity.source }
    })
  );

  return {
    activityStream,
    error,
    loading
  };
};

export default useActivityStream;
