import { cdnUrl } from '@utils/global';

/**
 * Constants and/or mock data?
 */

export const PLANS_AND_PRICES = {
  websitePlus: 25,
  basePlatform: 395,
  turbocharged: 695
};

const specialistIcon = `${cdnUrl}/icon-specialist.svg`;
const checkmarkIcon = `${cdnUrl}/icon-greenCheckmark.svg`;

export const CUSTOM_PLAN = {
  programs: [
    { icon: specialistIcon, nameKey: 'upgrade.customPlan.programs.team' },
    { icon: checkmarkIcon, nameKey: 'upgrade.customPlan.programs.research' },
    { icon: checkmarkIcon, nameKey: 'upgrade.customPlan.programs.seo' },
    { icon: checkmarkIcon, nameKey: 'upgrade.customPlan.programs.smm' },
    { icon: checkmarkIcon, nameKey: 'upgrade.customPlan.programs.advertising' },
    { icon: checkmarkIcon, nameKey: 'upgrade.customPlan.programs.creative' }
  ]
};
export const ENTERPRISE_REFERRER_CUSTOM_PLAN = {
  features: [
    {
      icon: specialistIcon,
      nameKey: 'upgrade.enterpriseReferrerCustomPlan.features.setupAndWebsite'
    },
    { icon: checkmarkIcon, nameKey: 'upgrade.enterpriseReferrerCustomPlan.features.setupPackage' },
    { icon: checkmarkIcon, nameKey: 'upgrade.enterpriseReferrerCustomPlan.features.contacts' },
    { icon: checkmarkIcon, nameKey: 'upgrade.enterpriseReferrerCustomPlan.features.config' }
  ],
  apps: [
    { icon: checkmarkIcon, nameKey: 'common:products.intelligence.title' },
    { icon: checkmarkIcon, nameKey: 'common:products.social.title' },
    { icon: checkmarkIcon, nameKey: 'common:products.content.title' }
  ]
};

export const UPGRADE_BILLING_PHONE_URI = 'tel:888-872-3734';

/**
 * Functions
 */

/**
 * Util that accepts an account number and a plan string and returns the appropriate checkout page route
 * @param {string} termsId
 * @param {boolean} hasSubscription
 * @returns {string} string pointing to the Checkout page route
 */
export function getCheckoutUri(termsId = '', hasSubscription = false) {
  if (!termsId) {
    throw new Error('getCheckoutUri: termsId is required');
  }
  return `/settings/profile/billing/checkout?termsId=${termsId}&hasSubscription=${hasSubscription}`;
}
