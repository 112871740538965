import useGlobalContext from '@hooks/useGlobalContext';
import { AccountAssignedEmployee } from '@typedefs/Account';
import { findEmployee } from '@utils/global';
import { phoneNumberParser } from '@utils/phoneNumber';

const DEFAULT_PHONE = '8332771327'; // Madwire support number
const DEFAULT_COUNTRY = 'US';

const useEmployee = () => {
  const {
    globalState: { account }
  } = useGlobalContext();

  const employee = findEmployee(account.assignedEmployees) as AccountAssignedEmployee;
  const phoneNumber = phoneNumberParser(employee?.phone || DEFAULT_PHONE, DEFAULT_COUNTRY);

  return { employee, phoneNumber };
};

export default useEmployee;
