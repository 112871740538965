import useModalManager from '@hooks/useModalManager';
import { Embed, Modal } from '@madwire/m360ui';
import PropTypes from 'prop-types';

const VideoEmbedModal = ({ src, title, embedOptions }) => {
  const { showModal, closeModal } = useModalManager();
  return (
    <Modal onClose={closeModal} show={showModal} size="lg" title={title}>
      <Embed title={title} src={src} config={embedOptions} />
    </Modal>
  );
};

VideoEmbedModal.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  embedOptions: PropTypes.object
};

export default VideoEmbedModal;
