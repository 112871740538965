import { getActivities } from '@data/activityStream/activities';
import useActivityStream from '@hooks/activityStream/useActivityStream';
import useInfiniteActivityStream from '@hooks/activityStream/useInfiniteActivityStream';
import useEmployee from '@hooks/useEmployee';
import {
  Button,
  ButtonLink,
  Card,
  CardBleedContainer,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  Flex,
  Heading,
  Link,
  Spinner,
  Text,
  useM360UI
} from '@madwire/m360ui';
import { ArrowDropDown, TaskAltOutlined } from '@mui/icons-material';
import { getLocale } from '@utils/general';
import moment from 'moment';
import { Trans, useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import DashboardActivityStreamItem from './ActivityStreamItem';

const COMPATABLE_LANGUAGES = ['en'];

/**
 * Helpers
 */
const buildStreamItem = (activity, index, activityStream) => {
  const activityMoment = moment(activity.timestamp);
  const formatString = activityMoment.isSame(moment(), 'year')
    ? 'dddd, MMMM Do'
    : 'dddd, MMMM Do YYYY';
  const formattedDay = activityMoment.format(formatString);
  const lastActivityMoment = moment(activityStream[index - 1]?.timestamp);
  const isSameDayAsLast = lastActivityMoment && lastActivityMoment.isSame(activityMoment, 'day');

  return (
    <div key={activity.id}>
      {!isSameDayAsLast && <Text mx={{ color: 'text.gray', mY: 4 }}>{formattedDay}</Text>}
      <DashboardActivityStreamItem key={activity.id} activity={activity} />
    </div>
  );
};

/**
 * Component
 */
const ActivityStream = ({
  isDetailed = false,
  shouldShowActivitySelector = true,
  category = 'all',
  limit = 4,
  mx = {},
  headerMx = {}
}) => {
  const { t } = useTranslation('dashboard');
  const { theme } = useM360UI();
  const activities = useMemo(() => getActivities(t), [t]);
  const [currentActivity, setCurrentActivity] = useState(activities[category]);
  const { phoneNumber } = useEmployee();

  const browserLanguagePref = getLocale().slice(0, 2);
  const language = COMPATABLE_LANGUAGES.includes(browserLanguagePref)
    ? browserLanguagePref
    : COMPATABLE_LANGUAGES[0];

  const streamHook = isDetailed ? useInfiniteActivityStream : useActivityStream;
  const stream = streamHook(activities, currentActivity, language, limit);
  const { activityStream, loading, isComplete } = stream;

  useEffect(() => {
    setCurrentActivity(activities[category]);
  }, [activities, category]);

  const showSpinner = (isDetailed && !isComplete) || (!isDetailed && loading);
  const showNoContentPrompt = !showSpinner && activityStream?.length === 0;

  return (
    <Card
      mx={{
        pt: 0,
        mb: 3,
        bg: 'neutral.20',
        overflowY: 'auto',
        ...mx
      }}
    >
      <CardBleedContainer
        mx={{
          py: 4,
          mb: 4,
          bg: 'white',
          borderBottom: `${theme.borderWidths[1]} solid ${theme.colors.component.border}`,
          ...headerMx
        }}
      >
        <Flex mx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Heading
            type="h6"
            mx={{
              mb: 0
            }}
          >
            {t('activityStream.recentActivity')}
          </Heading>
          {shouldShowActivitySelector && (
            <Dropdown
              overlay={
                <DropdownMenu>
                  {Object.values(activities).map(activity => (
                    <DropdownMenuItem
                      key={activity.label}
                      onClick={() => setCurrentActivity(activity)}
                    >
                      {activity.label}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenu>
              }
            >
              <Button rightIcon={<ArrowDropDown />}>{currentActivity.label}</Button>
            </Dropdown>
          )}
        </Flex>
      </CardBleedContainer>

      {activityStream && activityStream.map(buildStreamItem)}
      {showNoContentPrompt && (
        <Text
          mx={{
            mt: 9,
            width: 'full',
            fontSize: 'body',
            textAlign: 'center',
            color: 'text.gray',
            fontStyle: 'italic'
          }}
        >
          {t(isDetailed ? 'activityStream.noActivityIn60Days' : 'activityStream.noActivityIn3Days')}
        </Text>
      )}
      {showSpinner && (
        <Flex mx={{ w: 'full', justifyContent: 'center' }} ref={stream.triggerRef}>
          <Spinner mx={{ mt: 6, bg: 'transparent' }} />
        </Flex>
      )}
      {isDetailed && isComplete && !showNoContentPrompt && (
        <Flex flexDirection="column" alignItems="center" mx={{ gap: 2, mt: 4 }}>
          <TaskAltOutlined style={{ color: theme.colors.neutral[200] }} />
          <Text mx={{ textAlign: 'center', color: 'neutral.600' }}>
            <Trans t={t} i18nKey={'activityStream.endOfActivity'} components={{ br: <br /> }} />
          </Text>
          <Link href={phoneNumber.href}>{t('activityStream.contactSupport')}</Link>
        </Flex>
      )}
      {!isDetailed && (
        <Flex mx={{ justifyContent: 'center', alignItems: 'center' }}>
          <NextLink href="/activity-detail">
            <ButtonLink>{t('activityStream.viewMoreActivity')}</ButtonLink>
          </NextLink>
        </Flex>
      )}
    </Card>
  );
};

ActivityStream.propTypes = {
  isDetailed: PropTypes.bool,
  shouldShowActivitySelector: PropTypes.bool,
  category: PropTypes.oneOf(['all', 'accounts', 'billing', 'crm', 'payments']),
  limit: PropTypes.number,
  mx: PropTypes.object,
  headerMx: PropTypes.object
};

export default ActivityStream;
