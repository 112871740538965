import useEmployee from '@hooks/useEmployee';
import { Avatar, ButtonPrimary, Card, Flex, Heading, Link, Text, useM360UI } from '@madwire/m360ui';
import { cdnUrl, MARKETING_CONSULTANT, SUCCESS_MANAGER } from '@utils/global';
import { truncateText } from '@utils/mxStyles';
import { UPGRADE_BILLING_PHONE_URI } from '@utils/settings/profile/billing/upgrade';
import { Trans, useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { openPopupWidget } from 'react-calendly';

const DashboardContactCard = () => {
  const { t } = useTranslation('dashboard');
  const { theme } = useM360UI();
  const { employee } = useEmployee();
  const phoneNumber = employee?.phone || UPGRADE_BILLING_PHONE_URI.slice(4);

  const { title, buttonText } = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      employee && employee?.title === MARKETING_CONSULTANT
        ? {
            title: t('employee.marketing-consultant.title'),
            buttonText: t('employee.marketing-consultant.cta')
          }
        : employee && employee?.title === SUCCESS_MANAGER
        ? {
            title: t('employee.marketing-consultant.title'),
            buttonText: t('taskManager.helpItems.contact.cta')
          }
        : {
            title: t('employee.marketing-consultant.title'),
            buttonText: t('taskManager.helpItems.contact.cta')
          },
    [employee, t]
  );

  const { image, name, description } = useMemo(
    () =>
      employee
        ? {
            image: employee.photo,
            name: employee.name,
            description: (
              <>
                <Link
                  href={`mailto:${employee?.email}`}
                  mx={{ mb: 1, maxWidth: '100%', ...truncateText }}
                >
                  {employee?.email}
                </Link>
                <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
              </>
            )
          }
        : {
            image: `${cdnUrl}/shared-illustrations/icon-SupportRep.svg`,
            name: t('taskManager.helpItems.contact.title'),
            description: (
              <Text mx={{ mb: 1 }}>
                <Trans
                  t={t}
                  i18nKey="taskManager.helpItems.contact.description"
                  values={{ tel: phoneNumber }}
                  components={{ bold: <Link href={`tel:${phoneNumber}`} /> }}
                />
              </Text>
            )
          },
    [employee, phoneNumber, t]
  );

  return (
    <Card>
      <Heading type="h6" as="h2" mx={{ mb: 8 }}>
        {title}
      </Heading>
      <Flex alignItems="center">
        <Avatar
          size="md"
          src={image}
          mx={{ mr: 4, borderRadius: '50%', backgroundColor: theme.colors.blue[150] }}
          name={name}
        />
        <Flex
          mx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexGrow: 2
          }}
        >
          <Text mx={{ fontWeight: 'semibold', fontSize: 1, mb: 1 }}>{name}</Text>
          {description}
        </Flex>
      </Flex>
      {employee?.meetingLink ? (
        <ButtonPrimary
          mx={{ mt: 8, width: '100%' }}
          onClick={() => openPopupWidget({ url: employee?.meetingLink })}
        >
          {buttonText}
        </ButtonPrimary>
      ) : (
        <ButtonPrimary as="a" mx={{ mt: 8, width: '100%' }} href={`tel:${phoneNumber}`}>
          {t('taskManager.helpItems.contact.cta')}
        </ButtonPrimary>
      )}
    </Card>
  );
};

export default DashboardContactCard;
