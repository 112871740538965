import moment from 'moment';

export const getActivities = t => ({
  all: { label: t('dashboard:activityStream.allActivities') },
  accounts: { label: t('dashboard:activityStream.account'), value: 'accounts' },
  billing: { label: t('common:products.billing.title'), value: 'billing' },
  core: { label: t('dashboard:activityStream.core.title'), value: 'core' },
  crm: { label: t('common:products.crm.title'), value: 'crm' },
  payments: { label: t('common:products.payments.title'), value: 'payments' }
});

export const getDefaultItems = ({ createdDate, planName, t }) => {
  const timestamp = moment(createdDate).toISOString();
  const itemKey = 'activityStream.defaultItems';
  return [
    {
      id: 'defaultActivityItem1',
      event: 'billing.plan.created',
      source: { label: t('common:products.billing.title'), value: 'billing' },
      actor: {
        type: 'serviceAccount'
      },
      timestamp,
      messageParts: [
        {
          type: 'text',
          props: {
            value: t(`${itemKey}.planCreated.currentlyOn`)
          }
        },
        {
          type: 'link',
          props: {
            value: planName,
            href: '/settings/profile/billing',
            external: false
          }
        }
      ]
    },
    {
      id: 'defaultActivityItem2',
      event: 'account.milestone.created',
      source: { label: t('dashboard:activityStream.account'), value: 'accounts' },
      actor: {
        type: 'serviceAccount'
      },
      timestamp,
      messageParts: [
        {
          type: 'text',
          props: {
            value: t(`${itemKey}.welcome.welcome`)
          }
        },
        {
          type: 'link',
          props: {
            value: t(`${itemKey}.welcome.takeTour`),
            href: '/?appcue=013fffa6-2268-419c-bdf3-52e854b0f4b6',
            external: false
          }
        },
        {
          type: 'text',
          props: {
            value: t(`${itemKey}.welcome.dashboard`)
          }
        }
      ]
    }
  ];
};
