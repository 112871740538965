import { handleErrorMessage } from '@utils/global';
import { useSWRInfinite } from 'swr';

// https://swr.vercel.app/docs/pagination#useswrinfinite
// This wrapper adds a loading key for convenience and passes any
// error through the global handle error message.
const useFetchSWRInfinite = (getKey, fetcher, options) => {
  const { data, error, isValidating, mutate, setSize, size } = useSWRInfinite(
    getKey,
    fetcher,
    options
  );

  return {
    data,
    error: error && handleErrorMessage(error),
    loading:
      getKey && typeof fetcher === 'function' && (data === undefined || data === null) && !error,
    isValidating,
    mutate,
    setSize,
    size
  };
};

export default useFetchSWRInfinite;
