// Common reuseable styles for 360ui

export const truncateText = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

export const truncateTextDynamicWidth = {
  overflowWrap: 'break-word',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden'
};

export const rechartsLegendItemStyleWrapper = {
  '& .recharts-legend-item': {
    display: 'inline-flex !important',
    alignItems: 'center'
  }
};

export const inlineButtonLink = {
  p: 0,
  height: 'auto',
  lineHeight: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  textAlign: 'inherit'
};
