import { useBillingAccount } from '@context/BillingAccountContextProvider';
import { ActivityItem, Avatar, Image, Text, useM360UI } from '@madwire/m360ui';
import {
  AdminPanelSettingsOutlined,
  EmojiEventsOutlined,
  PersonOutlined,
  ReceiptOutlined,
  StoreOutlined
} from '@mui/icons-material';
import { cdnUrl } from '@utils/global';
import moment from 'moment';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import ActivityStreamItemCollapse from './ActivityStreamItemCollapse';

/**
 * Helpers
 */

/**
 * Function to map an event or a status to a styling variant
 * @param {string} eventOrStatus
 * @returns string
 */
const getVariant = eventOrStatus => {
  switch (eventOrStatus) {
    case 'Past Due':
    case 'Delinquent':
    case 'billing.invoice.failed':
    case 'payments.integration.ecwid.suspended':
    case 'payments.integration.ecwid.deleted':
      return 'danger';
    case 'Paid':
    case 'billing.invoice.otp':
    case 'billing.invoice.paid':
    case 'payments.integration.ecwid.restored':
      return 'success';
    case 'Trial':
    case 'billing.invoice.retry':
      return 'other';
    case 'Canceled':
    case 'Expired':
    case 'billing.invoice.attribution.changed':
      return 'subdued';
    case 'accounts.created':
      return 'milestone';
    default:
      return 'default';
  }
};

/**
 * Function to decode HTML entities in message content
 * while avoiding XSS attacks in user-generated names
 * @param {string}
 * @returns string
 */
const decodeEntities = string =>
  new DOMParser().parseFromString(string, 'text/html').querySelector('html').textContent || string;

/**
 * Type definition for Activity Message Part
 * @typedef {Object} MessagePart
 * @property {'link'|'text'} type
 * @property {Object} props
 * @property {string} props.value
 * @property {string|undefined} props.href
 * @property {string|undefined} props.tag
 * @property {boolean|undefined} props.external
 */

/**
 * Function to concatenate message parts into sibling nodes of the correct types
 * @param {Array.<MessagePart>} messageParts
 * @returns JSX.Element
 */
const formatContent = messageParts =>
  messageParts.map((part, index) => {
    switch (part.type) {
      case 'link':
        return (
          <NextLink
            // eslint-disable-next-line react/no-array-index-key
            key={`${part.type}-${index}`}
            href={part.props.href}
          >{`${decodeEntities(part.props.value)}`}</NextLink>
        );
      case 'list':
        return <ActivityStreamItemCollapse items={part.props.value} />;
      default:
        return (
          <Text
            as="span"
            // eslint-disable-next-line react/no-array-index-key
            key={`${part.type}-${index}`}
            mx={part.props.tag && part.props.tag === 'b' && { fontWeight: 'bold' }}
          >{`${decodeEntities(part.props.value)} `}</Text>
        );
    }
  });

/**
 * Component
 */
const ActivityStreamItem = ({ activity }) => {
  const {
    timestamp,
    event,
    source: { label, value },
    actor,
    details,
    messageParts
  } = activity;
  const activityMoment = moment(timestamp);
  const formatter = activityMoment.isSame(moment(), 'year')
    ? 'MMM D \\at h:mm a'
    : 'MMM D YYYY \\at h:mm a';
  const formattedTimestamp = activityMoment.format(formatter);
  const { accountData } = useBillingAccount();
  const { theme } = useM360UI();

  const variant = getVariant(label === 'Core' ? details.newStatus : event);

  const nonDefaultIconStyles = useMemo(
    () => (variant !== 'default' ? { color: theme.colors.white } : undefined),
    [theme, variant]
  );

  const icon = useMemo(() => {
    if (variant === 'milestone') {
      return <EmojiEventsOutlined style={nonDefaultIconStyles} />;
    }
    switch (value) {
      case 'billing':
        return <ReceiptOutlined style={nonDefaultIconStyles} />;
      case 'accounts':
        return <StoreOutlined style={nonDefaultIconStyles} />;
      case 'core':
        return <AdminPanelSettingsOutlined style={nonDefaultIconStyles} />;
      default:
        return actor.type === 'user' ? (
          <PersonOutlined style={nonDefaultIconStyles} />
        ) : (
          <Image mx={{ width: 'full' }} src={`${cdnUrl}/product-${value}.svg`} alt="" />
        );
    }
  }, [actor.type, nonDefaultIconStyles, value, variant]);

  const itemStyles = useMemo(() => {
    switch (variant) {
      case 'danger':
        return {
          bg: 'red.100',
          color: 'text.body',
          accent: theme.colors.state.danger
        };
      case 'success':
        return {
          bg: 'green.100',
          accent: theme.colors.green[1000],
          color: 'text.body'
        };
      case 'other':
        return {
          bg: 'blue.100',
          accent: theme.colors.blue[1000],
          color: 'text.body'
        };
      case 'subdued':
        return {
          bg: 'white',
          accent: theme.colors.neutral[600],
          color: 'neutral.600'
        };
      case 'milestone':
        return {
          bg: 'orange.100',
          accent: theme.colors.orange[1000],
          color: 'text.body'
        };
      default:
        return {
          bg: 'white',
          accent: theme.colors.neutral[100],
          color: 'text.body'
        };
    }
  }, [theme, variant]);

  const src = useMemo(() => {
    // TODO: uncomment when API supports user activity reporting
    // if (actor.type === 'user') return actor.profileUrl;
    if (value === 'accounts') return accountData?.accountIcon;
    return undefined;
  }, [accountData?.accountIcon, value]);

  return (
    <ActivityItem
      avatar={
        <Avatar
          // Name and Icon props are fallbacks, but name has precedence, so leave it out
          // in the case of a serviceAccount so the icon is used.
          // This approach must be used to ensure the users initials are used as a fallback
          // if the user does not have a profile picture loaded
          // actor.type = 'user' | 'serviceAccount'
          name={actor.type !== 'serviceAccount' ? actor.name : undefined}
          size="sm"
          type="pending"
          mx={{
            '& > span': {
              bg: itemStyles.accent,
              color: actor.type !== 'serviceAccount' ? 'white' : undefined,
              p: 2
            },
            position: 'unset'
          }}
          src={src}
          icon={icon}
        />
      }
      mx={{
        '& span + div ': { borderColor: itemStyles.accent, bg: itemStyles.bg }
      }}
      content={formatContent(messageParts)}
      timestamp={formattedTimestamp}
      origin={label}
    />
  );
};

ActivityStreamItem.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    actor: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.oneOf(['user', 'serviceAccount']).isRequired
      // TODO: uncomment when API supports user activity reporting
      // profileUrl: PropTypes.string
    }).isRequired,
    details: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        newStatus: PropTypes.string
      }),
      PropTypes.shape({
        paymentMethod: PropTypes.shape({
          type: PropTypes.string,
          identifier: PropTypes.string,
          endingIn: PropTypes.bool
        })
      })
    ]),
    event: PropTypes.string.isRequired,
    source: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired,
    timestamp: PropTypes.string.isRequired,
    messageParts: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        props: PropTypes.shape({
          value: PropTypes.string.isRequired,
          tag: PropTypes.string,
          href: PropTypes.string,
          external: PropTypes.bool
        })
      })
    ).isRequired
  })
};

export default ActivityStreamItem;
