import { apiActivity } from '@api/activity';
import { useBillingAccount } from '@context/BillingAccountContextProvider';
import useInfiniteScroll from '@hooks/useInfiniteScroll';
import { ENV } from '@utils/environment';
import moment from 'moment';
import { useEffect, useState } from 'react';

/**
 * Constants
 */
const PAGE_SIZE = 10;
const ENDPOINT_DATE_FORMAT_STRING = 'YYYY-MM-DD';
const START_DATE = moment().subtract(60, 'days').format(ENDPOINT_DATE_FORMAT_STRING);
const END_DATE = moment().format(ENDPOINT_DATE_FORMAT_STRING);

/**
 * Helpers
 */

/**
 * Component
 */
const useInfiniteActivityStream = (activities, searchActivity, language) => {
  const { accountData } = useBillingAccount();
  const { accountNumber } = accountData;
  const [isComplete, setIsComplete] = useState(false);

  /**
   * getKey function tells useSWRInfinte when to stop fetching data (null case)
   * When the API returns less than the limit size, there are no more
   */
  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData?.response?.length < PAGE_SIZE) {
      setIsComplete(true);
      return null;
    }
    return accountData
      ? [
          'logs/range',
          accountData.accountNumber,
          searchActivity.value,
          PAGE_SIZE,
          pageIndex + 1,
          START_DATE,
          END_DATE
        ]
      : null;
  };

  const { data, error, loading, ref } = useInfiniteScroll(
    accountNumber ? getKey : null,
    async (url, scopeId, source, limit, page, startDate, endDate) => {
      const res = await apiActivity({
        url,
        payload: {
          'scopes[account]': scopeId,
          'scopes[user]': accountData?.primaryUser?.id ?? undefined,
          page,
          startDate,
          endDate,
          limit,
          source
        },
        method: 'get',
        headers: {
          'M360-Activity-Context': `web:${ENV}:marketing360Url:${language}`
        }
      });
      return res?.data;
    }
  );

  const activityStream =
    (data &&
      data
        .map(dateSpanResponse => dateSpanResponse?.response)
        .flat()
        .map(activity => ({
          ...activity,
          source: activities[activity.source] || { label: activity.source, value: activity.source }
        }))) ||
    [];

  useEffect(() => setIsComplete(false), [searchActivity]);

  return {
    activityStream,
    error,
    loading,
    isComplete,
    triggerRef: ref
  };
};

export default useInfiniteActivityStream;
