import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
// These are wrapper components for libphonenumber-js or
// whatever library we choose to use in the future.
// This is done so if we ever choose to switch parsing libraries,
// we only have to do it here rather than across the app.

export const DEFAULT_COUNTRY = 'US';

/**
 * @param {string|number} number
 * @param {string} [countryCode = US] default 'US'
 * @returns phoneNumber object
 */
export const phoneNumberParser = (number, countryCode = DEFAULT_COUNTRY) => {
  try {
    const phoneNumber = parsePhoneNumber(number?.toString(), countryCode) || null;

    // Note - If you add return value to this function, please add a
    // test for it in phoneNumber.test.js
    return {
      formatted: phoneNumber?.formatNational(), // (303) 678-9012
      href: phoneNumber?.getURI(), // tel:+13036789012
      unformatted: phoneNumber?.nationalNumber, // 3036789012
      withCountryCode: phoneNumber?.number, // +13036789012
      isValid: phoneNumber?.isValid(),
      error: null
    };
  } catch (error) {
    return {
      formatted: null,
      href: null,
      unformatted: null,
      withCountryCode: null,
      isValid: false,
      error: error.message
    };
  }
};

/**
 * Formats the input as you type into (303) 678-9012 format
 * @param {string} value
 * @param {string} [countryCode = US] default 'US'
 * @returns formatted string
 */
export const phoneNumberAsYouType = (value, countryCode = DEFAULT_COUNTRY) => {
  if (!value) return '';

  const newValue = value.toString();

  if (countryCode === 'US') {
    // fixes issue with backspace getting stuck on ")"
    if (newValue.includes('(') && !newValue.includes(')')) {
      return newValue.replace('(', '');
    }
  }
  const phoneNumber = new AsYouType(countryCode)?.input(newValue);

  return phoneNumber;
};
