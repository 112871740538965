import { Button, Collapse, Flex, List, ListItem, Text } from '@madwire/m360ui';
import { ArrowDropDown } from '@mui/icons-material';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';

/*
 *
 * Component
 *
 */
const ActivityStreamItemCollapse = ({ items }) => {
  const { t } = useTranslation('dashboard');
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Flex flexDirection="column" alignItems="flex-start">
      <Button
        size="sm"
        onClick={() => setIsOpen(prev => !prev)}
        leftIcon={<ArrowDropDown />}
        mx={{
          fontWeight: 0,
          padding: 0,
          color: 'green',
          border: 'none',
          '& span': {
            color: 'neutral.600',
            transform: isOpen ? 'rotate(180deg)' : 'none',
            transition: 'transform 500ms',
            mr: 0
          },
          '&:hover, &:active, &:focus': {
            bg: 'transparent'
          }
        }}
      >
        <Text mx={{ mb: 0, color: 'neutral.600' }}>
          {t(isOpen ? 'activityStream.showLess' : 'activityStream.showMore')}
        </Text>
      </Button>
      <Collapse isOpen={isOpen}>
        <List mx={{ borderRadius: 0, borderWidth: 0, borderLeftWidth: 1 }}>
          {items.map(i => (
            <ListItem
              mx={{
                marginLeft: 2,
                fontSize: 0,
                p: 0,
                border: 'none'
              }}
              key={i}
            >
              {i}
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Flex>
  );
};

ActivityStreamItemCollapse.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string)
};

export default ActivityStreamItemCollapse;
