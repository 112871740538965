import { isProduction } from '@utils/environment';
import { apiRequest, generateDataOrParams } from './apiConfig';

const baseUrl = isProduction
  ? 'https://activity.marketing360.com'
  : 'https://activity.stage.marketing360.com';

export const apiActivity = ({ url, method, headers, payload, cache = false }) => {
  const baseRequest = apiRequest(baseUrl);
  const dataOrParams = generateDataOrParams(method);
  const config = {
    url,
    method,
    [dataOrParams]: payload,
    cache,
    headers
  };

  return baseRequest(config);
};
