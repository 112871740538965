import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import useFetchSWRInfinite from './useFetchSWRInfinite';

const useInfiniteScroll = (getKey, fetcher, options, intersectionObserverOptions) => {
  const { setSize, ...swrReturn } = useFetchSWRInfinite(getKey, fetcher, options);

  const { ref, inView } = useInView(intersectionObserverOptions);

  // If the scroll reaches the ref, set the page index for the next one
  useEffect(() => {
    if (inView) {
      setSize(size => size + 1);
    }
  }, [inView, setSize]);

  return { ...swrReturn, ref };
};

export default useInfiniteScroll;
