import { Box, Card, Flex, Grid, Heading, Link, Text } from '@madwire/m360ui';
import PropTypes from 'prop-types';

const TipsAndTricksCard = ({ title, videoButton, videoDescription, links, linkMx }) => (
  <Card>
    <Heading mx={{ fontSize: 'h6', mb: 5 }}>{title}</Heading>
    <Grid columns="108px 1fr" placeItems="center">
      <Box>{videoButton}</Box>
      <Text>{videoDescription}</Text>
    </Grid>
    <Flex mx={{ flexDirection: 'column' }}>
      {links.map(({ href, text }) => (
        <Link
          key={href}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          mx={linkMx || { marginTop: 5 }}
        >
          {text}
        </Link>
      ))}
    </Flex>
  </Card>
);

TipsAndTricksCard.propTypes = {
  title: PropTypes.string.isRequired,
  videoButton: PropTypes.element.isRequired,
  videoDescription: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({ href: PropTypes.string.isRequired, text: PropTypes.string.isRequired })
  ).isRequired,
  linkMx: PropTypes.object
};

export default TipsAndTricksCard;
